import React from 'react';
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaLinkedin } from 'react-icons/fa';

const iconStyle = (Icon) => <Icon />;

export const footerSocialData = [
	// {
	// 	name: 'Facebook',
	// 	icon: iconStyle(FaFacebook),
	// 	links: 'https://web.facebook.com/profile.php?id=100087396333457',
	// },
	{
		name: 'Instagram',
		icon: iconStyle(FaInstagram),
		links: 'https://www.instagram.com/laborertechnology/',
	},
		
	{
		name: 'LinkedIn',
		icon: iconStyle(FaLinkedin),
		links: 'https://www.linkedin.com/company/LaborerTechnology',
	},
];

export const footerData = [
	{
		title: 'Main',
		links: ['Blog', 'FAQs', 'Support', 'About us'],
	},
	{
		title: 'Product',
		links: ['Login', 'Personal', 'Business', 'Team'],
	},
	{
		title: 'Press',
		links: ['Logos', 'Events', 'Stories', 'Office'],
	},
	{
		title: 'Legal',
		links: ['GDPR', 'Privacy Policy', 'Terms of Service', 'Disclaimer'],
	},
];
