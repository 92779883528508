import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';


import { createRoot } from 'react-dom/client';
//import { createRoot } from 'react-dom';

createRoot(document.getElementById('root')).render(
	<React.StrictMode>
	  <App />
	</React.StrictMode>
  );





// ReactDOM.render(
// 	<React.StrictMode>
// 		<App />
// 	</React.StrictMode>,
// 	document.getElementById('root')
// );
