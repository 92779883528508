import React from 'react';
import Carousel from '../components/Carousel/Carousel';
import { Content } from '../components/Content/Content';
//import Features from '../components/Features/Features';
import Hero from '../components/Hero/Hero';

import { heroOne, heroTwo,heroThree } from '../data/HeroData';
import { MainHeading } from '../globalStyles';

// Hero Feature Content Carousel
/**
 * 
   saque <Features /> 
    
   del Home

 * @returns 
 */
const Home = () => {
	console.log('en home')
	return (
		<>
		   {/* <MainHeading>Empleabilidad agricola</MainHeading> */}
			<Hero />
			<Content {...heroOne} />
			<Content {...heroTwo} />
			<Content {...heroThree} />
		   	<Carousel />   
		<br></br>
		
		</>
	);
};

export default Home;
