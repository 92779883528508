import React from "react";
import {
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  FooterLogo,
  SocialIcon,
  FooterRights,
  FooterSocialIcon,
  FooterWrapper,
  FooterAddress,
  FooterColumn,
  FooterGrid,
} from "./FooterStyles";
import { footerData, footerSocialData } from "../../data/FooterData";
import { Row, Section } from "../../globalStyles";

function Footer() {
  return (
    <Section padding="4rem 0 2rem 0">
      <FooterWrapper>
        <FooterGrid justify="space-between">
          <FooterColumn id="footerLogo">
            <FooterLogo to="/">
              <SocialIcon src="./assets/logolaborer120x78.png" />
              Laborer Technology
            </FooterLogo>
            <FooterAddress>
              Apoquindo 2930, Piso 2, Oficina 201, Las Condes, Santiago - RM,
              Chile.
            </FooterAddress>

            <Row align="center" margin="auto  0 0 0" gap="1rem">
            <FooterAddress>Contacto: +56 942666684</FooterAddress>
              {footerSocialData.map((social, index) => (
                <FooterSocialIcon
                  key={index}
                  href={social.links}
                  target="_blank"
                  aria-label={social.name}
                >
                  {social.icon}
                </FooterSocialIcon>
              ))}
            </Row>
          </FooterColumn>

          {/*  agregado logo de corfo    */}
          <Row align="center" vlign="center" margin="auto  0 0 0" gap="1rem">
            
              {" "}
              <FooterLogo to="https://www.corfo.cl" target="_blank">
                <img height="120" src="./assets/logo-corfo.png" />
              </FooterLogo>
              <FooterLogo to="https://www.fedefruta.cl" target="_blank">
                <img height="120" src="./assets/logo-fedefruta.png" />
              </FooterLogo>
            </Row>
          
          {/*
	 				{footerData.map((footerItem, index) => (
						<FooterLinkItems key={index}>
							<FooterLinkTitle>{footerItem.title}</FooterLinkTitle>
							{footerItem.links.map((link, linkIndex) => (
								<FooterLink key={linkIndex} to="/">
									{link}
								</FooterLink>
							))}
						</FooterLinkItems>

					))}
				*/}
        </FooterGrid>
        <FooterRights>Laborer © 2022</FooterRights>
      </FooterWrapper>
    </Section>
  );
}

export default Footer;
