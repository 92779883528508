export const data = [
	
];




/**
 * 
 * objeto original 
 * 

const dataoriginal = [
	{
		to: '/',
		text: 'Home',
		id: 'about',
	},
	 {
		to: '/pricing',
		text: 'Precios',
	},
	{
		to: '/contacto',
		text: 'Contacto',
	},
	{
		to: '/signup',
		text: 'Registrarse',
	},
];


 */