import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, MainHeading } from '../../globalStyles';
import { HeroVideo, HeroSection, HeroText, ButtonWrapper, HeroButton } from './HeroStyles';

const Hero = () => {
	return (
		<HeroSection>
			<HeroVideo src="./assets/hero3.mp4" autoPlay loop muted />
			<Container>
				<MainHeading>Empleabilidad agricola</MainHeading>
				<HeroText>
					Ofertas laborales con las Mejores condiciones. 
					<br/>
					<br/>
					<br/>
				{/* <h3>Próximo lanzamiento<br/>(aplicación en desarrollo)</h3> */ }	 
				</HeroText>
				
				{/*
				<ButtonWrapper>
					<Link to="signup">
					</Link>
					<Button>Play Store</Button> 
					
					<HeroButton>Version Pc</HeroButton>
				</ButtonWrapper>
			    */}
			</Container>
		</HeroSection>
	);
};

export default Hero;
