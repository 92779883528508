export const data = [
	{
		title: 'Chile Agrícola',
		description:
			'	Es una plataforma de capacitación del Ministerio de Agricultura que te ofrece contenidos sobre temas relevantes del Agro enfocados en estrategias y nuevas tecnologías',
		image: './assets/carrousel/chileagricola.png',
		url: 'www.chileagricola.cl',
	
	},
	{
		title: 'Portal Fruticola',
		description: ' El medio de comunicación en la Industria Hortofrutícola. información actualizada a nivel global.',
		image: './assets/carrousel/portalfruticola.jpeg',
		url: 'www.portalfruticola.com',
	},
	{
		title: 'Mundo Agro',
		description: 'editorial especializada en el sector silvoagropecuario con múltiples soportes, destinada a satisfacer las necesidades de información y análisis del sector.',
		image: './assets/carrousel/mundoagro.jpg',
		url: 'www.mundoagro.cl',
		
	},
	{
		title: 'Fedefruta',
		description: ' Fedefruta operan comités de productores y exportadores, cuya labor fundamental se centra en la promoción del fruto que representan, ya sea en el mercado interno como externo',
		image: './assets/carrousel/fedefruta.jpg',
		url: 'www.fedefruta.cl',
	},
	{
		title: 'Simfruit',
		description: 'Portal Oficial de la Fruta Chilena de Exportación, mediante el cual, la Asociación de Exportadores de Frutas de Chile A.G., ASOEX',
		image: './assets/carrousel/simfruit.jpg',
		url: 'www.simfruit.cl',
	},
];

export const sliderSettings = {
	arrows: false,
	slidesToShow: 3,
	focusOnselect: false,
	accessability: false,
	responsive: [
		{
			breakpoint: 1280,
			settings: {
				slidesToShow: 2,
			},
		},

		{
			breakpoint: 900,
			settings: {
				slidesToShow: 1,
			},
		},
	],
};
