import React from 'react';
import GlobalStyle from './globalStyles';
//import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, Link, Outlet } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';

//Pages
import SignUp from './pages/SignupPage';
import Pricing from './pages/PricingPage';
import Footer from './components/Footer/Footer';
import Home from './pages/Home';

function App() {
	return (
	  <Router>
		<GlobalStyle />
		<Navbar />
	    <Routes>
        <Route exact path="/" component={Home} />
		<Route path="/signup" exact component={SignUp} />
		<Route path="/pricing" exact component={Pricing} />
        </Routes>  
				
		<Home/>
    
	
		
		<Footer />
	  </Router>
	);
  }
  
  export default App;
  
  
  
  
  




// function App() {
// 	return (
// 		<Router>
// 			<GlobalStyle />
// 			<Navbar />
// 			<Routes >
				
				
// 				<Route path="/" exact element={Home} />
//  				<Route path="/signup" exact element={SignUp} />
// 				<Route path="/pricing" exact element={Pricing} />

				
// 				{/* <Route path="/" exact component={Home} />
//  				<Route path="/signup" exact component={SignUp} />
// 				<Route path="/pricing" exact component={Pricing} /> */}
				
// 			</Routes>
// 			<Footer />
// 		</Router>
// 	);
// }

// export default App;
