export const heroOne = {
	reverse: true,
	inverse: true,
	topLine: {
		text: 'Proceso simplificado',
	},
	headline: "menor costo, rapides y eficiencia",
	description: 'Un proceso simplificado en la busqueda de puestos laborales, de seleccion automatizada',
	buttonLabel: 'Laborer innovación',
	imgStart: 'start',
	img: './assets/svg/Deal.svg',
	start: 'true',
};

export const heroTwo = {
	reverse: false,
	inverse: false,
	topLine: {
		text: 'Registro curricular',
	},
	headline: 'El trabajador puede mejorar su remuneración y condiciones laborales',
	description: 'La aplicacion registra las faenas y el trabajo realizado, identifica competencias y virtudes por medio del Feedback entre el trabajador y productor',
	buttonLabel: 'Laborer Curricular',

	linkTo: '/more',
	imgStart: 'start',
	img: './assets/svg/Connection.svg',
	start: 'true',
};

export const heroThree = {
	reverse: true,
	inverse: true,
	topLine: {
		text: 'Contactenos',
	},
	headline: 'Responderemos a la brevedad',
	description:
		'escribenos a contacto@laborer.cl',
	buttonLabel: 'Contacto',

	linkTo: '/download',
	imgStart: '',
	img: './assets/svg/ChartUp.svg',
	start: 'true',
};
