export const pricingData = [
	{
		title: 'Trabajadores',
		price: 'Gratuito',
		numAcc: '50-100 accounts',
		features: [
			'Acceso todo el año', 
			'Soporte 24/7', 
			'Ficha curricular', 
			'Buscador de faenas',
			'Alertas', 
			'Aplicacion Movil',
			'Aplicacion Web'
		],
	},
	{
		title: 'Productor',
		price: '1 faena gratuita',
		numAcc: '50-500 accounts',
		features: [
			'Acceso todo el año',
			'Aplicación Movil',
			'Aplicación Web'
		],
	},
	{
		title: 'Productor Premium',
		price: '00.000/mes',
		numAcc: '50-1000 accounts',
		features: [
			'Acceso todo el año', 
			'Soporte 24/7',
			'Multi-faenas', 
			'Informes',
			'Busqueda',
			'Alertas', 
			'Aplicación Movil',
			'Aplicación Web'
		],
	},

	{
		title: 'Productor Enterprise',
		price: '000.000/anual',
		numAcc: '1000+ Accounts',
		features: [
			'Acceso todo el año', 
			'Soporte 24/7', 
			'Getion de faenas', 
			'Informes',
			'Busqueda',
			'Alertas', 
			'Aplicación Movil',
			'Aplicación Web',
		]
	},
	{
		title: 'Convenios',
		price: 'Cotización',
		numAcc: '1000+ Accounts',
		features: [
			'Acceso todo el año', 
			'Soporte 24/7', 
			'Getión de faenas', 
			'Campaña Publicidad',
			'Busqueda inteligente', 
			'Aplicación Movil',
			'Aplicación Web',
			'Proyectos I+D+I',
		]
	},
];
